// Module
var code = `<template>
  <require from="@fortawesome/fontawesome-free/css/all.min.css"></require>
  <require from="/static/css/linearicons.css"></require>
  <require from="bootstrap/dist/css/bootstrap.min.css"></require>
  <require from="./styles.scss"></require>
  <require from="./components/footer-menu/footer-menu"></require>
  <require from="./components/left-side-bar/left-side-bar"></require>
  <!-- <require from="./components/notification-bar/notification-bar"></require> -->
  <!-- <require from="./components/notification-nugget/notification-nugget"></require> -->

  <!--require from="./nav-bar.html"></require-->

  <!-- <nav-bar router.bind="router"></nav-bar> -->
  <!-- Google Tag Manager (noscript) -->
  <!-- <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXLQWWT" height="0" width="0" style="display:none;visibility:hidden">
    </iframe>
  </noscript> -->
  <!-- End Google Tag Manager (noscript) -->
  <div class="page-host flex-row">
    <!-- <notification-bar  
    title="notification.title"
    body.bind="notification.body"
    if.bind="isShowingNotification"></notification-bar>  -->
    <!-- <notification-nugget title.bind="notification.title"
    body.bind="notification.body"
    icon.bind="notification.icon"
    is-showing.two-way="isShowingNotification"
    timing.bind="notification.timing"
    ></notification-nugget> -->
    <!-- if.bind="isShowingNotification" -->    
    
    <left-side-bar></left-side-bar>
    <div class="vertical-loading"  class.bind="router.isNavigating ? 'navigating' : ''">
      <div class="scanner"></div>
    </div>
    <div class="router-container flex-column">
      <router-view swap-order="after" class.bind="router.isNavigating ? 'navigating' : ''">
      </router-view>
      <!-- <div class="horizontal-loading"  class.bind="router.isNavigating ? 'navigating' : ''">
        <div class="scanner"></div>
      </div> -->
      <footer-menu></footer-menu>
    </div>
  </div>
  <!-- <div class="splash_loader" class.bind="router.isNavigating ? 'navigating' : ''">
    <img src="/static/img/banka_logo.png">
  </div> --> 
</template>`;
// Exports
export default code;